<template>
  <div>
    <div class="pa-6 app-container mb-2">
      <v-row class="justify-center">
        <v-col cols="12" sm="12" md="4" class="pa-0">
          <v-card class="home-card pa-4" v-if="windowWidth >= 950">
            <div class="home-card-title">
              <v-icon
                :size="windowWidth >= 400 ? '68px' : '40px'"
                color="secondary"
                >mdi-ticket-outline</v-icon
              >
              <div>Coupon</div>
            </div>
            <hr />
            <div class="home-card-text pt-4 overflow">
              <v-card
                class="card-cupom mb-2 d-flex align-center justify-space-between pa-4"
                v-for="(item, index) in cupons"
                :key="index"
                @click="exibirCupom(item)"
              >
                <div class="d-flex align-center cupom-icone">
                  <v-icon size="40px" color="secondary" left
                    >mdi-ticket-outline</v-icon
                  >
                  <div>Coupon</div>
                </div>
                <div class="d-flex align-center">
                  <div class="cupom-valor font-weight-bold pr-2">
                    {{ item.desconto }}
                  </div>
                  <div class="cupom-off">
                    <span class="font-weight-bold cupom-percentual">%</span>
                    <br />
                    <span>OFF</span>
                  </div>
                </div>
              </v-card>
              <div v-if="cupons.length == 0" class="red--text">
                No coupons available
              </div>
            </div>
          </v-card>
          <div v-if="windowWidth < 950">
            <v-card
              class="home-card pa-4 mb-2"
              v-for="(item, index) in cupons"
              :key="index"
              @click="exibirCupom(item)"
            >
              <div class="d-flex d-md-inline">
                <div class="home-card-title">
                  <v-icon
                    :size="windowWidth >= 400 ? '68px' : '40px'"
                    color="secondary"
                    left
                    >mdi-ticket-outline</v-icon
                  >
                  <div>Coupon</div>
                </div>
                <hr />
                <v-card-text
                  class="home-card-titulo d-flex justify-end align-center"
                >
                  <div class="d-flex align-center">
                    <div class="cupom-valor font-weight-bold pr-2">
                      {{ item.desconto }}
                    </div>
                    <div class="cupom-off">
                      <span class="font-weight-bold cupom-percentual">%</span>
                      <br />
                      <span class="off-responsivo">OFF</span>
                    </div>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="pa-0" v-if="!voucherQuery">
          <v-card class="home-card pa-4 mb-2">
            <div class="d-flex d-md-inline">
              <div class="home-card-title">
                <v-icon
                  :size="windowWidth >= 400 ? '68px' : '40px'"
                  color="secondary"
                  >mdi-star</v-icon
                >
                <div class="d-none d-md-flex">Points</div>
              </div>
              <hr />
              <v-card-text
                class="home-card-titulo d-flex justify-center align-center"
              >
                {{ pontos }}pts
              </v-card-text>
              <v-card-actions class="pa-0 d-flex justify-center align-center">
                <v-btn
                  color="primary"
                  text
                  class="text-caption"
                  @click="alterarTab"
                >
                  <v-icon class="text-caption">mdi-tag-multiple-outline</v-icon>
                  My Points
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="pa-0" v-if="!voucherQuery">
          <v-card class="home-card pa-4 mb-8">
            <div class="d-flex d-md-inline">
              <div class="home-card-title">
                <v-icon
                  :size="windowWidth >= 400 ? '68px' : '40px'"
                  color="secondary"
                  >mdi-emoticon-happy-outline</v-icon
                >
                <div class="d-none d-md-flex">Refer a friend</div>
              </div>
              <hr />
              <v-card-text
                class="home-card-titulo d-flex justify-center align-center"
              >
                <div class="d-flex align-center">
                  <div class="cupom-valor font-weight-bold pr-2">
                    {{ totalAmigos }}
                  </div>
                  <div class="cupom-off">
                    <span class="cupom-percentual">friends</span>
                    <br />
                    <span class="cupom-percentual">refered</span>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions class="pa-0 d-flex justify-center align-center">
                <v-btn
                  color="primary"
                  text
                  class="text-caption"
                  @click="compartilhar"
                >
                  <v-icon class="text-caption">mdi-share-variant</v-icon>
                  Share
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center">
      <v-dialog v-model="modalCompartilhamento" max-width="600px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Refer a Friend</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="modalCompartilhamento = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-4">
            <h2 class="mb-4">Refer a friend and receive discounts</h2>
            <p class="title">
              Share your link with your friends and get discounts on services at the salon.
            </p>
            <p class="title">
              Please note that the discount will be valid after your friend uses the first service in the salon.
            </p>
            <hr />
            <div class="d-flex justify-center mt-2">
              <v-btn
                color="primary"
                text
                class="text-caption"
                @click="compartilharLink"
              >
                <v-icon class="text-caption">mdi-share-variant</v-icon>
                Share
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="modalQRCode" max-width="400px">
        <v-card v-if="cupomSelecionado">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Coupon</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeModalQRCode">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-0 black--text">
            <div class="d-flex justify-center">
              <img
                :src="cupomSelecionado.qrCode"
                alt="QR Code"
                class="qrcode"
              />
            </div>
            <div class="d-flex align-center justify-center pb-4">
              <div class="cupom-valor font-weight-bold pr-2">
                {{ cupomSelecionado.voucher.desconto }}
              </div>
              <div class="cupom-off">
                <span class="font-weight-bold cupom-percentual">%</span>
                <br />
                <span class="off-responsivo">OFF</span>
              </div>
            </div>
            <div class="pl-2 pr-2 pb-2 d-flex justify-center">
              {{ cupomSelecionado.servico }}
            </div>
            <div
              class="pl-2 pr-2 pb-2 d-flex justify-center"
              v-if="
                cupomSelecionado.voucher.dataInicio &&
                cupomSelecionado.voucher.dataFim
              "
            >
              Valid
              {{ cupomSelecionado.voucher.dataInicioFormatada.substr(0, 10) }} -
              {{ cupomSelecionado.voucher.dataFimFormatada.substr(0, 10) }}
            </div>
            <hr />
            <div class="d-flex justify-space-between pa-4 font-weight-bold">
              <div>
                {{ cupomSelecionado.codigo }}
              </div>
              <div></div>
              <div
                @click="copiarTexto"
                :class="
                  textoCopiado
                    ? 'd-flex align-center'
                    : 'cursor-pointer d-flex align-center'
                "
              >
                <v-icon color="primary" left>{{
                  textoCopiado ? "mdi-check" : "mdi-content-copy"
                }}</v-icon>
                {{ textoCopiado ? "Copied" : "Copy" }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import VoucerService from "../../service/voucher-service.js";
import ClienteService from "../../service/cliente-service.js";

const voucerService = new VoucerService();
const clienteService = new ClienteService();

export default {
  name: "Carrossel",
  props: ["voucherQuery"],
  data() {
    return {
      cupons: [],
      pontos: null,
      totalAmigos: null,
      windowWidth: window.innerWidth,
      key: 0,
      modalCompartilhamento: false,
      linkCompartilhamento: null,
      loadingCupons: false,
      loadingPontos: false,
      loadingAmigos: false,
      login: JSON.parse(localStorage.getItem("login")),
      loading: false,
      cupomSelecionado: null,
      textoCopiado: false,
      modalQRCode: false,
    };
  },
  methods: {
    closeModalQRCode() {
      this.modalQRCode = false;
      this.cupomSelecionado = null;
    },
    copiarTexto() {
      navigator.clipboard.writeText(this.cupomSelecionado.codigo);
      this.textoCopiado = true;
      setTimeout(() => {
        this.textoCopiado = false;
      }, 3000);
    },
    async exibirCupom(item) {
      this.cupomSelecionado = null;
      this.textoCopiado = false;
      this.loading = true;
      let result = await voucerService.getById(item.id, window.location.origin, this.voucherQuery ? false : true);
      if (result?.statusCode === 200) {
        this.modalQRCode = true;
        this.cupomSelecionado = result?.data;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    async compartilharLink() {
      this.loading = true;
      var login = JSON.parse(localStorage.getItem("login"));
      let result = await voucerService.gerarVoucherPrimeiroAcesso(login.clienteId, window.location.origin);
      if (result?.statusCode === 200) {
        this.linkCompartilhamento = result?.data.data;
        if (navigator.share !== undefined) {
          navigator.share({
            title: "Refer a friend",
            text: "Click on the link and register",
            url: this.linkCompartilhamento,
          });
          this.modalCompartilhamento = false;
        }
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    async compartilhar() {
      this.loading = true;
      let result = await clienteService.linkCompartilhamento(
        JSON.parse(localStorage.getItem("login")).clienteId,
        window.location.origin
      );
      if (result?.statusCode === 200) {
        this.modalCompartilhamento = true;
        this.linkCompartilhamento = result?.data.mensagem;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    alterarTab() {
      this.$emit("alterarTab", 3);
    },
    async listarCupons() {
      this.loadingCupons = true;
      let result = this.voucherQuery
        ? await voucerService.getByVoucherId(this.voucherQuery, window.location.origin, false)
        : await voucerService.getCupons(this.login.clienteId);
      if (result?.statusCode === 200) {
        this.loadingCupons = false;
        this.cupons = result?.data;
        console.log(this.cupons);
      } else {
        this.cuponst = [];
        this.loadingCupons = false;
      }
    },
    async listarPontos() {
      this.loadingPontos = true;
      let result = await clienteService.pontosCliente(this.login.clienteId);
      if (result?.statusCode === 200) {
        this.loadingPontos = false;
        this.pontos = result?.data.ponto;
      } else {
        this.pontos = null;
        this.loadingPontos = false;
      }
    },
    async listarAmigos() {
      this.loadingAmigos = true;
      let result = await clienteService.totalIndicacoes(this.login.clienteId);
      if (result?.statusCode === 200) {
        this.loadingAmigos = false;
        this.totalAmigos = result?.data.data;
      } else {
        this.pontos = null;
        this.loadingAmigos = false;
      }
    },
    loadingFalse() {
      if (
        this.loadingCupons == false &&
        this.loadingPontos == false &&
        this.loadingAmigos == false
      )
        this.$emit("changeLoading", false);
      else this.$emit("changeLoading", true);
    },
  },
  created: function () {
    this.listarCupons();
    if(!this.voucherQuery){
      this.listarPontos();
      this.listarAmigos();
    }
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  watch: {
    loadingAmigos() {
      this.loadingFalse();
    },
    loadingCupons() {
      this.loadingFalse();
    },
    loadingPontos() {
      this.loadingFalse();
    },
    loading() {
      this.$emit("changeLoading", this.loading);
    },
  },
};
</script>
<style scoped>
.home-card {
  transition: 0.3s ease;
  user-select: none;
}
.home-card-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 2rem;
}
.home-card-text {
  height: 250px;
  text-align: center;
}
.home-card-titulo {
  height: 214px;
  font-size: 2rem;
  font-weight: bold;
  color: black !important;
}
.card-cupom:hover {
  background-color: #ffebab;
}
.card-cupom {
  height: 80px;
  width: 100%;
  transition: 0.3s ease;
  cursor: pointer;
}
.overflow {
  overflow-y: auto;
}
.cupom-valor {
  font-size: 2.5rem;
}
.cupom-off {
  line-height: 16px;
}
.cupom-icone {
  font-size: 1.5rem;
}
.cupom-percentual {
  font-size: 1.3rem;
}
.off-responsivo {
  font-size: 1rem;
  font-weight: normal;
}
.qrcode {
  height: 300px;
}
.cursor-pointer {
  cursor: pointer;
}
@media (max-width: 960px) {
  .home-card-titulo {
    height: auto;
  }
  .cupom-off {
    line-height: 12px;
    text-align: center;
  }
}
@media (max-width: 400px) {
  .home-card-title {
    font-size: 1.5rem;
  }
  .home-card-titulo {
    font-size: 1.5rem;
  }
  .cupom-valor {
    font-size: 1.5rem;
  }
  .cupom-percentual {
    font-size: 0.8rem;
  }
  .off-responsivo {
    font-size: 0.7rem;
  }
  .cupom-off {
    line-height: 8px;
  }
}
</style>