<template>
  <div>
    <v-app-bar color="black" dark height="90px">
      <div class="logo-container">
        <div class="d-flex justify-space-between align-center menu-container">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="imagem-modulo" v-on="on" @click="alterarTab(0)">
                <img src="@/assets/logo_dourado_alt.png" width="80px" />
              </div>
            </template>
            <span>Home</span>
          </v-tooltip>
          <div
            :class="
              'menu-item' + (selectedIndex == 1 ? ' menu-item-active' : '')
            "
            @click="alterarTab(1)"
            v-if="!voucherQuery"
          >
            <v-icon class="mr-1">mdi-pencil-outline</v-icon> My Profile
          </div>
          <div
            :class="
              'menu-item' + (selectedIndex == 2 ? ' menu-item-active' : '')
            "
            @click="alterarTab(2)"
            v-if="!voucherQuery"
          >
            <v-icon class="mr-1">mdi-gift-outline</v-icon> Exchenge your points
          </div>
          <div
            :class="
              'menu-item' + (selectedIndex == 3 ? ' menu-item-active' : '')
            "
            @click="alterarTab(3)"
            v-if="!voucherQuery"
          >
            <v-icon class="mr-1">mdi-tag-multiple-outline</v-icon> My Points
          </div>
          <div
            :class="
              'menu-item' + (selectedIndex == 4 ? ' menu-item-active' : '')
            "
            @click="alterarTab(4)"
            v-if="!voucherQuery"
          >
            <v-icon class="mr-1">mdi-shield-half-full</v-icon> Privacy Policy
          </div>
        </div>
        <div class="pl-4 cliente-container" v-if="!voucherQuery">
          <v-menu offset-y open-on-hover max-width="150" right offset-x>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                Hello
                {{ usuario ? usuario.nome : null }}
                <v-icon>mdi-menu-down</v-icon>
              </div>
            </template>
            <v-list>
              <v-list-item @click="exibirSair">
                <v-list-item-icon>
                  <v-icon color="black">mdi-exit-to-app</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Exit</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="menu-responsivo" @click="exibirMenuResposivo" v-if="!voucherQuery">
          <v-icon size="30">mdi-menu</v-icon>
        </div>
      </div>
      <div class="header-divisor"></div>
    </v-app-bar>
    <div class="footer">
      Copyright © {{ new Date().getFullYear() }} FM Fabio Merli Hair. All Rights
      Reserved
    </div>

    <v-dialog v-model="modalResponsivo" fullscreen>
      <v-list class="fill-height">
        <v-list-item>
          <v-list-item-icon>
            <v-icon size="40" color="black">mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <div class="d-flex align-center">
              <h2 class="cliente-hello-responsivo">
                Hello {{ usuario ? usuario.nome : null }}
              </h2>
              <v-spacer></v-spacer
              ><v-btn icon @click="modalResponsivo = false">
                <v-icon size="30" color="black">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-list-item-title>
        </v-list-item>
        <hr>
        <v-list-item @click="alterarTab(0)">
          <v-list-item-icon>
            <v-icon color="black">mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item @click="alterarTab(1)">
          <v-list-item-icon>
            <v-icon color="black">mdi-pencil-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>My Profile</v-list-item-title>
        </v-list-item>
        <v-list-item @click="alterarTab(2)">
          <v-list-item-icon>
            <v-icon color="black">mdi-gift-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Exchenge your points</v-list-item-title>
        </v-list-item>
        <v-list-item @click="alterarTab(3)">
          <v-list-item-icon>
            <v-icon color="black">mdi-tag-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>My Points</v-list-item-title>
        </v-list-item>
        <v-list-item @click="alterarTab(4)">
          <v-list-item-icon>
            <v-icon color="black">mdi-shield-half-full</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Privacy Policy</v-list-item-title>
        </v-list-item>
        <v-list-item @click="exibirSair">
          <v-list-item-icon>
            <v-icon color="black">mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Exit</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-dialog>
    <div justify="center">
      <v-dialog v-model="modalSair" max-width="600px">
        <v-card>
          <v-toolbar dark color="black">
            <v-toolbar-title>Exit</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pt-4 pl-4 pr-4">
            <p class="title black--text">
              Do you really want to leave the site?
            </p>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              right
              class="text-caption"
              @click="modalSair = false"
            >
              <v-icon class="text-caption">mdi-close</v-icon>No
            </v-btn>
            <v-btn
              color="primary"
              text
              class="text-caption"
              @click="confirmarSair"
            >
              <v-icon class="text-caption">mdi-check</v-icon>Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: ["voucherQuery"],
  data() {
    return {
      selectedIndex: 0,
      usuario: null,
      modalResponsivo: false,
      modalSair: false,
    };
  },
  methods: {
    confirmarSair() {
      this.modalSair = false;
      localStorage.removeItem("login");
      this.$router.push("/");
    },
    exibirSair() {
      this.modalResponsivo = false;
      this.modalSair = true;
    },
    exibirMenuResposivo() {
      this.modalResponsivo = true;
    },
    alterarTab(index) {
      this.modalResponsivo = false;
      this.selectedIndex = index;
      this.$emit("alterarTab", index);
    },
  },
  mounted: function () {
    this.paginaInicial = this.$router.currentRoute.fullPath == "/home";
    this.usuario = JSON.parse(localStorage.getItem("login"));
  },
};
</script>
<style scoped>
.cliente-hello-responsivo {
  white-space: break-spaces;
}
.logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}
.imagem-modulo {
  cursor: pointer;
}
.header-divisor {
  background-image: linear-gradient(270deg, #b2862c, #e0c25d);
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #000000;
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 12px;
  padding: 6px 0px;
  z-index: 1;
}

.menu-container {
  width: 800px;
}

.menu-item {
  cursor: pointer;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
}

.menu-item i {
  color: inherit !important;
}

.menu-item:hover,
.menu-responsivo i:hover,
.menu-item-active {
  color: #b2862c;
}

.menu-responsivo {
  display: none;
  cursor: pointer;
}

.cliente-container {
  cursor: default;
  font-size: 0.9rem;
  white-space: nowrap;
}

@media (max-width: 900px) {
  .menu-item,
  .cliente-container {
    display: none;
  }
  .menu-responsivo {
    display: unset;
  }
}

.modal-container {
  position: fixed;
}
</style>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.columns-resize-bar:hover {
  border-left: 1px dashed #ccc;
}
</style>