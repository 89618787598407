<template>
  <div>
    <div class="carousel-container">
      <v-card>
        <v-carousel
          cycle
          :height="windowWidth <= 600 ? 300 : 400"
          :key="key"
          hide-delimiters
        >
          <v-carousel-item v-for="(slide, i) in slides" :key="i">
            <div class="d-flex justify-center fill-height">
              <img :src="slide.imagem" class="carousel-image" />
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </div>
  </div>
</template>
<script>
import BannerService from "../../service/banner-service.js";
import ImageService from "../../service/image-service.js";

const imageService = new ImageService();
const bannerService = new BannerService();

export default {
  name: "Carrossel",
  props: ["voucherQuery"],
  data() {
    return {
      slides: [],
      windowWidth: window.innerWidth,
      key: 0,
    };
  },
  methods: {
    async listarBannersAtivos() {
      let result = await bannerService.listarBannersAtivos(!this.voucherQuery ? true : false);
      if (result?.statusCode === 200) {
        this.slides = result?.data;
        await this.slides.forEach(async (x) => {
          const banner = await imageService.validateImage(x.nomeArquivo);
          x.imagem = banner.status == 404 ? null : banner.url;
          this.key += 1;
        });
      } else {
        this.slides = [];
      }
    },
  },
  created: function () {
    this.listarBannersAtivos();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>
<style scoped>
.carousel-container {
  width: 100%;
}
.carousel-image {
  width: auto;
  height: 100%;
}
@media (max-width: 600px) {
  .carousel-image {
    width: 100%;
    height: auto;
  }
}
</style>