<template>
  <div>
    <div class="d-flex justify-center">
      <div class="extratos-container pt-4 pb-4 pl-2 pr-2">
        <h2 class="mb-6">My points</h2>
        <table>
          <tr
            v-for="(item, index) in extratos"
            :key="index"
            class="mb-1 item-extrato"
          >
            <td class="ocultar-sm pr-2 nowrap">{{ item.dataFormatada }}</td>
            <td :class="item.credito ? 'black--text' : 'green--text'">
              <v-icon :color="item.credito ? 'black' : 'green'" left>{{
                item.credito ? "mdi-check" : "mdi-ticket-outline"
              }}</v-icon
              >{{ item.servico }}
            </td>
            <td class="font-weight-bold pl-2 nowrap">
              {{ item.pontoFormatado }}pts
            </td>
          </tr>
          <tr class="red--text item-extrato">
            <td colspan="3" v-if="extratos.length == 0">No points available</td>
          </tr>
          <tr v-if="extratos.length > 0" class="espacador">
            <td></td>
          </tr>
          <tr class="item-extrato" v-if="extratos.length > 0">
            <td class="borderless">Balance:</td>
            <td class="ocultar-sm borderless"></td>
            <td class="font-weight-bold pl-2 nowrap borderless">
              {{ totalGeral > 0 ? "+" + totalGeral : "-" + totalGeral }}pts
            </td>
          </tr>
        </table>
      </div>
    </div>
    <MensagemErro
      :mensagemAlerta="mensagemAlerta"
      :alertaErro="alertaErro"
      @ocultarErro="alertaErro = false"
      :login="false"
    />
  </div>
</template>
<script>
import ExtratoService from "../../service/extrato-service.js";
import MensagemErro from "../../components/mensagem-erro.vue";

const extratoService = new ExtratoService();

export default {
  components: {
    MensagemErro
  },
  name: "Extrato",
  data() {
    return {
      mensagemAlerta: "",
      alertaErro: false,
      filtros: extratoService.listarFiltros(
        JSON.parse(localStorage.getItem("login")).clienteId
      ),
      extratos: [],
      totalGeral: null,
      loading: false
    };
  },
  methods: {
    async listarExtratos() {
      this.loading = true;
      let result = await extratoService.listarExtrato(this.filtros);
      if (result?.statusCode === 200) {
        this.extratos = result?.data.lista;
        this.totalGeral = result?.data.total;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
  },
  created: function () {
    this.listarExtratos();
  },
  watch: {
    loading() {
      this.$emit("changeLoading", this.loading);
    }
  },
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
.extratos-container {
  max-width: 1000px;
  width: 100%;
}
.item-extrato {
  font-size: 1.2rem;
  padding: 10px 0px !important;
}
.nowrap {
  white-space: nowrap;
}
td {
    border-bottom: 1px solid #e0e0e0;  
    padding: 10px 0px;  
  }
@media (max-width: 700px) {
  .ocultar-sm {
    display: none;
  }
  td {
    border-bottom: 1px solid #e0e0e0;  
    padding: 10px 0px;  
  }
  .item-extrato {
    font-size: 0.8rem;    
  }
}

.espacador {
  height: 12px;
}

.borderless {
  border-top: 2px solid black;
  border-bottom: none !important;
}
</style>