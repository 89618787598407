<template>
  <div>
    <div class="d-flex justify-center">
      <div class="vouchers-container pt-4 pb-4 pl-2 pr-2">
        <h2 class="mb-6">Exchenge your points</h2>
        <div
          class="
            d-flex
            justify-space-between
            align-center
            item-voucher
            mb-1
            font-weight-bold
          "
        >
          <span>Balance:</span>
          <span class="ml-2">{{totalPontos}}pts</span>
        </div>
        <hr />
        <div class="overflow-container">
          <div v-for="(item, index) in vouchers" :key="index" class="mb-1">
            <div class="d-flex justify-space-between align-center item-voucher">
              <span class="item-margin">{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon :disabled="item.ponto > totalPontos" right color="primary" class="cursor-pointer" @click="trocarPontos(item)"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <!-- <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" />
            <div class="d-flex justify-space-between align-center item-voucher">
              <span>{{ item.servico }}</span>
              <div>
                <span class="font-weight-bold ml-2">{{ item.ponto }}pts</span>
                <v-icon right color="primary" class="cursor-pointer" @click="trocarPontos"
                  >mdi-tag-multiple-outline</v-icon
                >
              </div>
            </div>
            <hr class="d-sm-none" /> -->
          </div>
        </div>
      </div>
    </div>
    <div justify="center" v-if="voucherSelecionado">
      <v-dialog v-model="modalConfirmacao" max-width="600px">
        <v-card>
          <v-toolbar dark color="black">
            <v-toolbar-title>Exchange points</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pt-4 pl-4 pr-4">
            <p class="title black--text">
              Do you really want to exchange {{voucherSelecionado.ponto}}pts for service: "{{voucherSelecionado.servico}}"?
            </p>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              right
              class="text-caption"
              @click="closeModalConfirmacao"
            >
              <v-icon class="text-caption">mdi-close</v-icon>No
            </v-btn>
            <v-btn
              color="primary"
              text
              class="text-caption"
              @click="confirmarTroca"
            >
              <v-icon class="text-caption">mdi-check</v-icon>Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <ModalSucesso
      :mensagem="mensagemAlerta"
      :alertaSucesso="alertaSucesso"
      :titulo="mensagemTitulo"
      @ocultarSucesso="closeModalConfirmacao"
    />
    <MensagemErro
      :mensagemAlerta="mensagemAlerta"
      :alertaErro="alertaErro"
      @ocultarErro="alertaErro = false"
      :login="false"
    />
  </div>
</template>
<script>
import VoucherService from "../../service/voucher-service.js";
import ExtratoService from "../../service/extrato-service.js";
import MensagemErro from "../../components/mensagem-erro.vue";
import ModalSucesso from "../../components/modal-sucesso.vue";
const voucherService = new VoucherService();
const extratoService = new ExtratoService();

export default {
  components: {
    MensagemErro,
    ModalSucesso
  },
  name: "ListaVouchers",
  data() {
    return {
      mensagemTitulo: "",
      mensagemAlerta: "",
      alertaErro: false,
      alertaSucesso: false,
      vouchers: [],
      loading: false,
      modalConfirmacao: false,
      totalPontos: 0,
      loadingVoucher: false,
      loadingExtrato: false,
      voucherSelecionado: null,
      clienteId: JSON.parse(localStorage.getItem("login")).clienteId
    };
  },
  methods: {
    async listarVouchersPontos() {
      this.loadingVoucher = true;
      let result = await voucherService.listarVouchersPontos();
      if (result?.statusCode === 200) {
        this.vouchers = result?.data;
        this.loadingVoucher = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loadingVoucher = false;
      }
    },
    async listarTotalPontos() {
      this.loadingExtrato = true;
      let result = await extratoService.getTotalGeralByClienteId(this.clienteId);
      if (result?.statusCode === 200) {
        this.totalPontos = result?.data.data;
        this.loadingExtrato = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loadingExtrato = false;
      }
    },
    async confirmarTroca(){
      this.loading = true;
      let result = await voucherService.gerarVoucherPontos(this.voucherSelecionado.id, this.clienteId);
      if (result?.statusCode === 200) {
        this.modalConfirmacao = false;
        this.mensagemTitulo = "Exchange successfully";
        this.mensagemAlerta = `You exchanged ${this.voucherSelecionado.ponto}pts for service: "${this.voucherSelecionado.servico}" successfully`;
        this.alertaSucesso = true;
        this.loading = false;
        this.$emit("reloadOtherComponents", false);
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
    closeModalConfirmacao(){
      this.voucherSelecionado = null;
      this.modalConfirmacao = false;
      this.mensagemAlerta = null;
      this.mensagemTitulo = null;
      this.alertaSucesso = false;
      this.listarVouchersPontos();
      this.listarTotalPontos();
    },
    trocarPontos(voucher){
      this.modalConfirmacao = true;
      this.voucherSelecionado = voucher;
    },
    loadingFalse() {
      if (
        this.loadingVoucher == false &&
        this.loadingExtrato == false 
      )
        this.$emit("changeLoading", false);
      else this.$emit("changeLoading", true);
    },
  },
  created: function () {
    this.listarVouchersPontos();
    this.listarTotalPontos();
  },
  watch: {
    loading() {
      this.$emit("changeLoading", this.loading);
    },
    loadingVoucher(){
      this.loadingFalse();
    },
    loadingExtrato(){
      this.loadingFalse();
    }
  },
};
</script>
<style scoped>
.vouchers-container {
  max-width: 1000px;
  width: 100%;
}
.item-voucher {
  font-size: 1.2rem;
}
.overflow-container {
  overflow-y: auto;
  height: calc(100vh - 250px);
  overflow-x: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.item-margin{
  margin: 10px 0px !important;
}
@media (max-width: 700px) {
  .item-voucher {
    font-size: 0.8rem;
  }
}
</style>