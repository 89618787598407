<template>
  <div>
    <Loading v-if="loading" />
    <HeaderApp ref="header" @alterarTab="alterarTab($event)" :voucherQuery="voucherQuery"/>
    <div>
      <v-tabs v-model="tabAtiva" id="tabs" class="tabs">
        <v-tab-item>
          <div class="cliente-tab-item">
            <Carrossel :voucherQuery="voucherQuery"/>
            <CardsHome
              :voucherQuery="voucherQuery"
              :key="'cards' + cardsKey"
              @alterarTab="alterarTab($event)"
              @changeLoading="changeLoading($event)"

            />
          </div>
        </v-tab-item>
        <v-tab-item v-if="!voucherQuery">
          <div class="cliente-tab-item">
            <Perfil @changeLoading="changeLoading($event)" />
          </div>
        </v-tab-item>
        <v-tab-item v-if="!voucherQuery">
          <div class="cliente-tab-item">
            <ListaVouchers
              @changeLoading="changeLoading($event)"
              @reloadOtherComponents="reloadOtherComponents"
            />
          </div>
        </v-tab-item>
        <v-tab-item v-if="!voucherQuery">
          <div class="cliente-tab-item">
            <Extrato
              @changeLoading="changeLoading($event)"
              :key="'extrato' + extratoKey"
            />
          </div>
        </v-tab-item>
        <v-tab-item v-if="!voucherQuery">
          <div class="cliente-tab-item">
            <Politica @changeLoading="changeLoading($event)" />
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>
<script>
import HeaderApp from "../../components/header-app.vue";
import Carrossel from "./carrossel.vue";
import CardsHome from "./cards-home.vue";
import Politica from "./politica.vue";
import ListaVouchers from "./lista-vouchers.vue";
import Perfil from "./perfil.vue";
import Extrato from "./extrato.vue";
import StorageService from "../../service/storage-service.js";
import Loading from "../../components/loading.vue";

const storageService = new StorageService();

export default {
  name: "Home",
  components: {
    HeaderApp,
    Carrossel,
    Politica,
    ListaVouchers,
    Perfil,
    Extrato,
    Loading,
    CardsHome,
  },
  data() {
    return {
      paginas: [],
      tabAtiva: 0,
      loading: false,
      cardsKey: 0,
      extratoKey: 0,
      voucherQuery: null
    };
  },
  methods: {
    reloadOtherComponents() {
      this.cardsKey += 1;
      this.extratoKey += 1;
    },
    alterarTab(index) {
      this.tabAtiva = index;
      const location = window.location.href.indexOf("#") == -1 ? window.location.href : window.location.href.split("#")[0];
      window.location.href = location + "#" + index;
    },
    changeLoading(loading) {
      this.loading = loading;
    },
  },
  created: function () {
    this.paginas = JSON.parse(localStorage.getItem("login"))?.paginas;
    setTimeout(() => {
      document.getElementById("tabs").children[0].remove();
      document.getElementById("tabs").className += " show";
    }, 10);
    this.voucherQuery = this.$route.query.voucher;
  },
  mounted: async function () {
    if(!this.voucherQuery)
      await storageService.validarToken();

    setTimeout(() => {
      document.getElementsByTagName("html")[0].style.overflow = "hidden auto";
    }, 100);
  },
  watch:{
    $route (to){
      if(to.hash)
        this.$refs.header.alterarTab(parseInt(to.hash.split('#')[1]));
    }
  } 
};
</script>
<style scoped>
.tabs {
  opacity: 0;
  transition: 0.3s ease;
}
.show {
  opacity: 1;
}
.cliente-tab-item {
  margin-bottom: 32px;
}
</style>