<template>
  <div>
    <div class="d-flex justify-center">
      <div class="perfil-container pa-4">
        <h2 class="mb-6">My profile</h2>
        <v-form ref="form" class="pt-4" v-if="cliente">
          <v-row class="pt-2 pb-2">
            <v-col cols="12" xs="12" md="6" class="pa-0 d-flex align-center justify-center">
              <Thumb
                :item="cliente"
                @selecionarImagem="selecionarImagem($event)"
              />
            </v-col>
            <v-col cols="12" xs="12" md="6" class="d-flex pb-4">
              <v-row>
                <v-col cols="12" xs="12" class="mb-2">
                  <h1 class="pl-2 pl-sm-4 pl-md-0 perfil-titulo"> {{ cliente.usuario.nome }}</h1>
                  <h2 class="pl-2 pl-sm-4 pl-md-0 perfil-sub-titulo"><v-icon color="black" size="30" left>mdi-phone</v-icon>{{ cliente.usuario.telefone }}</h2>
                </v-col>
                <v-col cols="12" xs="12">
                  <h2 class="pl-2 pl-sm-4 pl-md-0 perfil-sub-titulo"><v-icon color="black" size="30" left>mdi-email-outline</v-icon>{{ cliente.usuario.email }}</h2>
                </v-col>
                <v-col cols="12" xs="12">
                  <h2 class="pl-2 pl-sm-4 pl-md-0 perfil-sub-titulo"><v-icon color="black" size="30" left>mdi-instagram</v-icon>{{ cliente.instagram }}</h2>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <MensagemErro
      :mensagemAlerta="mensagemAlerta"
      :alertaErro="alertaErro"
      @ocultarErro="alertaErro = false"
      :login="false"
    />
    <ModalSucesso
      :mensagem="mensagemAlerta"
      :alertaSucesso="alertaSucesso"
      :titulo="mensagemTitulo"
      @ocultarSucesso="ocultarSucesso"
    />
  </div>
</template>
<script>
import Thumb from "../../components/thumb.vue";
import ClienteService from "../../service/cliente-service.js";
import ImageService from "../../service/image-service.js";
import MensagemErro from "../../components/mensagem-erro.vue";
import ModalSucesso from "../../components/modal-sucesso.vue";

const imageService = new ImageService();
const clienteService = new ClienteService();

export default {
  name: "Perfil",
  components: {
    Thumb,
    MensagemErro,
    ModalSucesso
  },
  data() {
    return {
      mensagemTitulo: "",
      mensagemAlerta: "",
      alertaSucesso: false,
      alertaErro: false,
      cliente: null,
      loading: false,
      login: JSON.parse(localStorage.getItem("login"))
    };
  },
  methods: {
    ocultarSucesso() {
      this.alertaSucesso = false;
    },
    async selecionarImagem(callback) {
      if (callback.success) {
        this.cliente.foto = callback.data.base64;
        const obj = {
          nomeArquivo : callback.data.name,
          base64String: callback.data.base64
        }
        this.loading = true;
        let result = await clienteService.atualizarFoto(obj, this.login.clienteId);
        if (result?.statusCode === 200) {
          this.mensagemAlerta = result?.data.mensagem;
          this.mensagemTitulo = "Photo update";
          this.alertaSucesso = true;
          this.loading = false;
        } else {
          this.mensagemAlerta = result?.data.mensagem;
          this.alertaErro = true;
          this.loading = false;
        }
      } else {
        this.mensagemAlerta = callback.data;
        this.alertaErro = true;
      }
    },
    async selecionarCliente() {
      this.loading = true;
      let result = await clienteService.selecionarCliente(this.login.clienteId);
      if (result?.statusCode === 200) {
        this.cliente = result?.data;
        const foto = await imageService.validateImage(this.cliente.foto);
        this.cliente.foto = foto.status == 404 ? null : foto.url;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    },
  },
  created: function () {
    this.selecionarCliente();
  },
  watch: {
    loading() {
      this.$emit("changeLoading", this.loading);
    },
  },
};
</script>
<style scoped>
.perfil-container {
  max-width: 1000px;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 600px){
  .perfil-titulo {
    font-size: 1.2rem;
  }
  .perfil-sub-titulo i{
    font-size: 20px !important;
  }
  .perfil-sub-titulo{
    font-size: 1rem;
  }
}
</style>