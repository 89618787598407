<template>
  <div>
    <vue-editor
      v-model="politicas"
      id="cliente-politica"
      disabled
      :editorOptions="editorOptions"
    />
    <MensagemErro
      :mensagemAlerta="mensagemAlerta"
      :alertaErro="alertaErro"
      @ocultarErro="alertaErro = false"
      :login="false"
    />
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import PoliticaPrivacidadeService from "../../service/politica-privacidade-service.js";
import MensagemErro from "../../components/mensagem-erro.vue";

const politicaPrivacidadeService = new PoliticaPrivacidadeService();

export default {
  name: "Politica",
  components: {
    VueEditor,
    MensagemErro
  },
  data() {
    return {
      mensagemAlerta: "",
      alertaErro: false,
      politicas: null,
      editorOptions: {
        modules: {
          toolbar: false,
        },
      },
      loading: false
    };
  },
  methods: {
    async listarPoliticas(){
      this.loading = true;
      let result = await politicaPrivacidadeService.consultarPolitica(false);
      if (result?.statusCode === 200) {
        this.politicas = result?.data.texto;
        this.loading = false;
      } else {
        this.mensagemAlerta = result?.data.mensagem;
        this.alertaErro = true;
        this.loading = false;
      }
    }
  },
  created: function(){
    this.listarPoliticas();
  },
  watch: {
    loading() {
      this.$emit("changeLoading", this.loading);
    }
  },
};
</script>
<style>
#cliente-politica {
  height: calc(100vh - 122px);
}
</style>